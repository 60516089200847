:root {
  --sidebar-background: #f5f5f5;
  --sidebar-scrollbar: #989898;
  --sidenavbar-border-left: #3b7ddd;
  --sidenav-brand-bg: #ffffff;
  --sidenav-collapsed: #212121;
  --sidebar-header-color: #212121;
  --sidebar-width: 250px;
}

body {
  position: relative;
  overflow-x: hidden;
  background: #eeeeee;
  overflow-y: auto !important;
}

a:hover {
  text-decoration: none;
}

a:focus {
  box-shadow: none !important;
}

body,
html {
  height: 100%;
  font-family: "Poppins-regular";
}

/*---------------------------------
Common
----------------------*/

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz19 {
  font-size: 19px;
}

.fz20 {
  font-size: 20px;
}

.fz21 {
  font-size: 21px;
}

.fz22 {
  font-size: 22px;
}

.fz23 {
  font-size: 23px;
}

.fz24 {
  font-size: 24px;
}

.fz25 {
  font-size: 25px;
}

.fz26 {
  font-size: 26px;
}

.fz27 {
  font-size: 27px;
}

.fz28 {
  font-size: 28px;
}

.fz29 {
  font-size: 29px;
}

.fz30 {
  font-size: 30px;
}

.fz31 {
  font-size: 31px;
}

.fz32 {
  font-size: 32px;
}

.fz33 {
  font-size: 33px;
}

.fz34 {
  font-size: 34px;
}

.fz35 {
  font-size: 35px;
}

.fz36 {
  font-size: 36px;
}

.fz37 {
  font-size: 37px;
}

.fz38 {
  font-size: 38px;
}

.fz39 {
  font-size: 39px;
}

.fz40 {
  font-size: 40px;
}

.fz41 {
  font-size: 41px;
}

.fz42 {
  font-size: 42px;
}

.fz45 {
  font-size: 45px;
}

.fz50 {
  font-size: 50px;
}

.fz54 {
  font-size: 54px;
}

.fz60 {
  font-size: 60px;
}

.regular_font {
  font-family: "Poppins-regular";
}

.semibold_font {
  font-family: "Poppins-semibold";
}

.bold_font {
  font-family: "Poppins-bold";
}

.medium_font {
  font-family: "Poppins-medium";
}

.gray_light {
  color: #c2c2c2;
}

.custom_form_control {
  background-color: #eeeeee;
  height: 40px;
  border-radius: 5px;
}

/*.custom_btn_new{
  padding: 12px;
  height: auto;
  background-color: #76a400;
}*/

/* -------------------
Login / Forget Password / Signup
----------------------*/

.admin-login__wrapper {
  min-height: 100%;
}

.admin-login__wrapper .admin-login__content {
  margin: 0;
}

.admin-login__content {
  position: relative;
  left: 0;
  margin-left: 230px;
  min-height: 100%;
}

.admin-login__main-content {
  padding: 10px;
}

.admin-login__container {
  max-width: 433px;
  margin: 50px auto;
}

.admin-login__container .card {
  margin-bottom: 30px;
  background-color: #fff;
}

.card-border-color {
  border-top: 3px solid #c9c9c9 !important;
}

.card-border-color-primary {
  border-top-color: #4285f4 !important;
}

.admin-login__container .card .card-header {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 0;
}

.admin-login__description {
  text-align: center;
  display: block;
  line-height: 20px;
  font-size: 1rem;
  color: #5a5a5a;
  margin-top: 11px;
}

.admin-login__container .form-control {
  border-width: 1px;
  border-top-color: #bdc0c7;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 1.1538rem;
  -webkit-transition: none;
  transition: none;
  height: 3rem;
}

.row.login-tools {
  padding: 10px 0 0;
  margin-bottom: 16px;
}

.login-submit,
.row.login-submit {
  padding: 10px 0 0;
  margin-bottom: 1.3842rem;
}

.admin-login__title {
  text-align: center;
  display: block;
  font-size: 1.308rem;
  font-weight: 300;
}

.btn-social {
  min-width: 31px;
  padding: 0 8px;
  line-height: 38px;
}

.btn-color.btn-facebook {
  color: #fff;
  background-color: #5d82d1;
  border-color: #5d82d1;
}

.btn-color.btn-google-plus {
  color: #fff;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
}

/*---------------------------------
sidebar
----------------------*/

#sidebar-wrapper {
  z-index: 1000;
  height: 100%;
  width: var(--sidebar-width);
  left: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--sidebar-background);
  transition: all 0.5s ease;
}

@-moz-document url-prefix() {
  /* Disable scrollbar Firefox */
  #sidebar-wrapper {
    scrollbar-width: none;
  }
}

#sidebar-wrapper {
  margin: 0;
  /* remove default margin */
  scrollbar-width: none;
  /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none;
  /* Disable scrollbar IE 10+ */
  overflow-y: scroll;
}

#sidebar-wrapper::-webkit-scrollbar,
#sidebar-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* Disable scrollbar Chrome/Safari/Webkit */
}

.left-sidebar-scroll {
  height: calc(100vh - 92px);
  position: relative;
  margin: 0 auto;
  overflow: auto;
  background-color: #1d522b;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  border-radius: 13px;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.always-visible.ps-container > .ps-scrollbar-x-rail,
.always-visible.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.sidebar-brand {
  font-weight: 600;
  font-size: 1.15rem;
  padding: 20px 25px 0px;
  display: block;
  color: #ffffff;
  background-color: #1d522b;
}

.sidebar-header {
  text-transform: capitalize;
  padding: 1.5rem 1.5rem 0.375rem !important;
  font-size: 14px;
  color: var(--sidebar-header-color);
}

.navbar-nav > li > a,
.submenu-box ul li a {
  color: #fff;
  text-transform: capitalize;
  font-size: 15px;
  padding: 20px 25px !important;
  display: block;
  font-weight: 400;
  position: relative;
  z-index: 2;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
}

.submenu-box ul li a {
  padding: 20px 10px 20px 60px !important;
  color: #fff;
}

.navbar-nav > li > a.active,
.submenu-box ul li a.active {
  color: #5fb700 !important;
}

.sidebar-elements .active .img-fluid {
  filter: invert(45%) sepia(79%) saturate(1839%) hue-rotate(45deg)
    brightness(100%) contrast(97%);
}

.navbar-nav .has-sub > a.collapsed::after {
  transition: 0.4s ease;
}

.navbar-nav .has-sub > a.collapsed::after {
  color: var(--sidenav-collapsed);
  font-size: 16px;
  content: "\f107";
  font-family: fontAwesome;
}

.navbar-nav .has-sub > a.collapsed::after {
  color: #fff;
}

.navbar-nav .has-sub > a::after {
  position: absolute;
  right: 20px;
  top: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  color: #fff;
  display: block;
  content: "\f106";
  font-family: "FontAwesome";
  font-weight: 900;
  font-size: 16px;
  line-height: 30px;
  margin-top: -15px;
}

.navbar-nav .has-sub > a.open::after {
  position: absolute;
  right: 20px;
  top: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  color: #fff;
  display: block;
  font-family: "FontAwesome";
  font-weight: 900;
  font-size: 16px;
  line-height: 30px;
  margin-top: -15px;
  content: "\f107" !important;
}

#wrapper.toggled {
  padding-left: var(--sidebar-width);
}

.toggled#sidebar-wrapper {
  /* width: 0px !important; */
  height: 100%;
  left: -288px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.5s ease;
}

.navbar-nav li {
  height: 55px;
  display: block !important;
  /*margin: 2px 0px;*/
}

.nav-item .nav-link {
  display: block;
  color: white !important;
  text-transform: capitalize;
  text-decoration: none;
  padding: 6px 10px;
  transition: 0.4s ease;
}

.navbar-nav > li > a i:before {
  margin: 0px 5px 0px 0px;
  font-size: 14px;
}

/*---------------------------------
sidebar
----------------------*/

/*---------------------------------
main-content
----------------------*/

#page-content-wrapper {
  /* width: 100%; */
  width: calc(100% - var(--sidebar-width));
  margin-left: var(--sidebar-width);
  transition: all 0.5s ease;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-left: var(--sidebar-width);
  transition: all 0.5s ease;
}

.toggled#page-content-wrapper {
  /* margin-left: 270px; */
  margin-left: 0px;
  transition: all 0.5s ease;
}

.my-navbar.toggled {
  margin-left: 0px;
}

/*---------------------------------
main-content
----------------------*/

/*---------------------------------
cross-bar animation
----------------------*/
.min-vh-100 {
  min-height: 100vh !important;
}
.nav-icon1 {
  z-index: 999;
  position: relative;
  display: block;
  width: 23px;
  margin: 0px 30px 0px 25px;
  cursor: pointer;
  height: 25px;
  background-color: #fff;
}

.nav-icon1 span {
  position: absolute;
  top: 0;
  width: 100%;
  height: 3px;
  cursor: pointer;
  background-color: #5d5d5d;
  left: 0;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}

.nav-icon1:hover span:nth-of-type(1) {
  top: -3px;
}

.nav-icon1:hover span:nth-of-type(3) {
  top: 19px;
}

.nav-icon1 span:nth-of-type(1) {
  top: 0;
}

.nav-icon1 span:nth-of-type(2) {
  top: 8px;
}

.nav-icon1 span:nth-of-type(3) {
  top: 16px;
}

.nav-icon1.open span:nth-of-type(1) {
  top: 8px;
  transform: rotate(135deg);
}

.nav-icon1.open span:nth-of-type(2) {
  top: 8px;
  opacity: 0;
  left: -30px;
}

.nav-icon1.open span:nth-of-type(3) {
  top: 8px;
  transform: rotate(-135deg);
}

/*---------------------------------
cross-bar animation
----------------------*/

/*---------------------------------
header navbar design
----------------------*/

.my-navbar {
  padding: 0px;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  margin-left: var(--sidebar-width);
  transition: all 0.5s ease;
}

.toggled .my-navbar {
  margin-left: 0px;
}

.text-gray-600 {
  color: #858796 !important;
}

.nav-link .img-profile {
  height: 40px;
  width: 40px;
}

.navbar-nav > li > a > i {
  font-size: 18px;
  color: var(--sidenav-collapsed);
  margin: 8px 0px 0px 0px;
  padding: 0px;
}

.navbar-nav > li > a.active > i {
  color: var(--sidenav-brand-bg);
}

.badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: 6px;
  margin-top: 4px;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.nav-flag,
.nav-icon {
  padding: 0.1rem 0.8rem;
  display: block;
  font-size: 1.5rem;
  color: #6c757d;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  line-height: 1.4;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-nav .dropdown-menu {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.dropdown-menu-lg {
  min-width: 20rem;
}

.position-relative {
  position: relative !important;
}

.nav-item .indicator {
  background: var(--sidenavbar-border-left);
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  height: 18px;
  width: 18px;
  padding: 1px;
  position: absolute;
  top: 0;
  right: -8px;
  text-align: center;
  transition: top 0.1s ease-out;
  font-size: 0.675rem;
  color: #fff;
}

.dropdown-menu-header .dropdown-item {
  padding: 5px 10px;
  font-size: 12px;
}

.dropdown-menu-header .dropdown-item i {
  width: 17px;
}

/*---------------------------------
header navbar design
----------------------*/

/* ----------------------
main content 
---------------------------*/

.mt-65 {
  margin-top: 80px;
}

.sub-content {
  min-height: 52vh;
}

.btn-light {
  border-color: #dbdbdb;
}

/*---------------------------------
main-top card
----------------------*/

.card {
  padding-bottom: 30px;
  border: none;
  box-shadow: 0 0 0.875rem 0 rgba(33, 37, 41, 0.05);
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

/* 
.card .card-title {
  position: relative;
  font-weight: 600;
  margin-bottom: 10px;
}

.card .card-subtitle {
  font-weight: 300;
  margin-bottom: 10px;
  color: #a1aab2;
  margin-top: -0.375rem;
} */

.card-title {
  font-size: 0.875rem;
  color: #495057;
  position: relative;
  font-weight: 600;
  margin-bottom: 10px;
}

.card-body h1 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
}

.card-header-divider {
  border-bottom: 1px solid #d9d9d9;
  background-color: transparent;
  margin: 0 1.538rem 8px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1.2307rem;
}

.card-subtitle {
  font-weight: 300;
  margin-bottom: 10px;
  color: #a1aab2;
  margin-top: -0.375rem;
  display: block;
}

.card-header-divider .card-subtitle {
  display: block;
  font-size: 1rem;
  color: #a1aab2;
  margin-top: 0;
  margin-bottom: 0;
}

.text-gray-800 {
  color: #5a5c69 !important;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/*---------------------------------
main-top card
----------------------*/

/*---------------------------------
main-table
----------------------*/

.m-r-10 {
  margin-right: 10px;
}

.btn {
  /*-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);*/
  padding: 0 10px;
  /*font-size: 1rem;*/
  /*line-height: 28px;*/
  border-radius: 2px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle-custome {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 7px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-info {
  color: #fff;
  background-color: #2962ff;
  border-color: #2962ff;
}

.btn-orange {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-success {
  color: #fff !important;
  background-color: #36bea6 !important;
  border-color: #36bea6 !important;
}

.btn-purple {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.table td,
.table th {
  padding: 5px 10px;
  font-size: 15px;
  color: #333;
  vertical-align: initial;
  border-top: 1px solid #dee2e6;
  vertical-align: inherit;
}
.table th {
  padding: 10px !important;
}

.table thead th {
  vertical-align: middle;
  color: #c2c2c2;
  font-family: "Poppins-regular";
  font-weight: initial;
  border: 2px solid #dee2e6;
}
.table h5 {
  font-size: 16px;
  font-weight: 600;
  color: #585858;
}
.table td, 
.table th {
   white-space: nowrap;
   width: 1%;
}

.custom_btn {
  background-color: #76a400;
  color: #fff;
  border-radius: 30px;
  padding: 12px 21px;
  border: 1px solid #76a400;
}

.blue_color {
  color: #76a400 !important;
}

.underline:hover {
  text-decoration: underline;
}

/*---------------------------------
main-table
----------------------*/

/*---------------------------------
footer
----------------------*/

footer.footer {
  padding: 1rem 0.875rem;
  direction: ltr;
  background: #fff;
  margin-left: var(--sidebar-width);
  transition: all 0.5s ease;
}

.footer.toggled {
  margin-left: 0px;
}

footer.footer ul {
  margin: 0px;
  list-style: none;
}

.footer ul li {
  display: inline-block;
  margin: 0px 7px;
}

.text-muted {
  color: #6c757d !important;
  font-size: 14px;
}

/*---------------------------------
footer
----------------------*/

/*  -----------------------
404 Page
--------------------------- */

.admin-error.admin-error-404 {
  padding: 100px 0 0;
}

.admin-error .error-container {
  text-align: center;
}

.admin-error .error-number {
  display: block;
  font-size: 11.54rem;
  font-weight: 700;
  line-height: 240px;
  margin-bottom: 30px;
  max-height: 240px;
}

.admin-error .error-number:after {
  padding-top: 0.25%;
  display: block;
  content: "";
}

.admin-error .error-description {
  font-size: 2.308rem;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 30px;
}

.admin-error .error-goback-text {
  font-size: 1.692rem;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 15px;
}

.admin-error .error-goback-button {
  margin-bottom: 30px;
}

.admin-error .error-goback-button .btn {
  min-width: 153px;
  text-align: center;
}

/*  gritter Css */

#gritter-notice-wrapper {
  width: 360px;
  top: 82px;
}

.gritter-item-wrapper {
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.gritter-item-wrapper .gritter-item {
  font-family: Roboto, Arial, sans-serif;
  background: 0 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gritter-item-wrapper .gritter-item .gritter-img-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 94px;
  flex: 0 0 94px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #302f2f;
}

.gritter-item-wrapper .gritter-item .gritter-img-container .gritter-image {
  float: none;
}

.gritter-item-wrapper .gritter-item .gritter-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image,
.gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image {
  padding: 15px 35px 17px 15px;
  color: #d9d9d9;
}

.gritter-item-wrapper
  .gritter-item
  .gritter-content.gritter-with-image
  .gritter-close,
.gritter-item-wrapper
  .gritter-item
  .gritter-content.gritter-without-image
  .gritter-close {
  display: block;
  top: 0;
  right: 0;
  left: auto;
  height: 30px;
  width: 30px;
  font-size: 0;
  background: 0 0;
  text-indent: inherit;
  opacity: 0.4;
}

.gritter-item-wrapper
  .gritter-item
  .gritter-content.gritter-with-image
  .gritter-close:hover,
.gritter-item-wrapper
  .gritter-item
  .gritter-content.gritter-without-image
  .gritter-close:hover {
  opacity: 0.8;
}

.gritter-item-wrapper
  .gritter-item
  .gritter-content.gritter-with-image
  .gritter-close:after,
.gritter-item-wrapper
  .gritter-item
  .gritter-content.gritter-without-image
  .gritter-close:after {
  content: "\f00d";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  color: #fff;
  font-size: 1rem;
  text-shadow: none;
  line-height: 35px;
  margin-left: 7px;
}

.gritter-item-wrapper
  .gritter-item
  .gritter-content.gritter-with-image
  .gritter-title,
.gritter-item-wrapper
  .gritter-item
  .gritter-content.gritter-without-image
  .gritter-title {
  text-shadow: none;
  font-weight: 300;
  font-size: 1.308rem;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image p,
.gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image p {
  font-size: 0.9231rem;
  line-height: 19px;
}

.gritter-item-wrapper .gritter-item .gritter-content .gritter-without-image {
  border-left: 0;
}

.gritter-item-wrapper.img-rounded {
  border-radius: 0;
}

.gritter-item-wrapper.img-rounded .gritter-img-container .gritter-image {
  border-radius: 50%;
}

.gritter-item-wrapper.clean {
  background: #fff;
}

.gritter-item-wrapper.clean .gritter-item {
  color: #404040;
}

.gritter-item-wrapper.clean .gritter-item .gritter-img-container {
  background-color: #fafafa;
}

.gritter-item-wrapper.clean .gritter-item .gritter-content {
  color: #404040;
}

.gritter-item-wrapper.clean .gritter-item .gritter-content .gritter-close {
  opacity: 0.5;
}

.gritter-item-wrapper.clean
  .gritter-item
  .gritter-content
  .gritter-close:after {
  color: #404040;
}

.gritter-item-wrapper.color .gritter-item .gritter-content {
  color: #fff;
}

.gritter-item-wrapper.color .gritter-item .gritter-content .gritter-close {
  opacity: 0.7;
}

.gritter-item-wrapper.color
  .gritter-item
  .gritter-content
  .gritter-close:hover {
  opacity: 1;
}

.gritter-item-wrapper.primary {
  background: #4285f4;
}

.gritter-item-wrapper.success {
  background: #34a853;
}

.gritter-item-wrapper.warning {
  background: #fbbc05;
}

.gritter-item-wrapper.danger {
  background: #ea4335;
}

.gritter-item-wrapper.dark {
  background: #3d3c3c;
}

/*  Form Elements */

.select2-container > .select2-dropdown {
  min-width: 300px;
}
.select2-selection {
  padding: 5px;
  height: 40px !important;
}
.select2-selection__arrow {
  top: 7px !important;
}

.select2-results__option {
  padding-right: 20px;
  vertical-align: middle;
}

.select2-results__option:before {
  content: "";
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  border: 2px solid #e9e9e9;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 20px;
  vertical-align: middle;
}

.select2-results__option[aria-selected="true"]:before {
  font-family: fontAwesome;
  content: "\f00c";
  color: #fff;
  background-color: #f77750;
  border: 0;
  display: inline-block;
  padding-left: 3px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #fff;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #eaeaeb;
  color: #272727;
}

.select2-container--default .select2-selection--multiple {
  margin-bottom: 10px;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-radius: 4px;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: #f77750;
  border-width: 2px;
}

.select2-container--default .select2-selection--multiple {
  border-width: 2px;
}

.select2-container--open .select2-dropdown--below {
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.select2-selection .select2-selection--multiple:after {
  content: "hhghgh";
}

.form-group.row label {
  white-space: nowrap;
}

.custom-radio-icon {
  padding: 7px 0;
  width: 52px;
  height: 52px;
}

.custom-radio-icon .custom-control-label::after,
.custom-radio-icon .custom-control-label::before {
  display: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-radio-icon input[type="radio"]:checked + .custom-control-label {
  border-color: #4285f4;
  color: #4285f4;
  background-color: #fff;
}

.custom-radio-icon .custom-control-label {
  cursor: pointer;
  margin: 0;
  width: 52px;
  height: 52px;
  line-height: 52px;
  border: 1px solid #d5d8de;
  border-radius: 50%;
  text-align: center;
  font-size: 36px;
  vertical-align: middle;
  color: gray;
}

/* Custome checkbox */

.custom-checkbox .custom-control-label:before {
  content: "";
  width: 1.699rem;
  height: 1.699rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0 0;
}

.custom-control {
  min-height: 1.7rem;
  min-width: 1.7rem;
  margin-top: 0.1875rem;
  margin-bottom: 1.1105rem;
}

.custom-control.custom-checkbox {
  padding-left: 2.2rem;
}

.custom-control-label::after {
  position: absolute;
  top: -0.135714rem;
  left: -2.2rem;
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-control-label:after,
.custom-control-label:before {
  top: 0;
  left: -2.2rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-control-input:checked ~ .custom-control-label::after {
  color: #4285f4;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "done";
  font-size: 1.5452rem;
  font-family: "Material Icons";
  margin: 0 0;
  font-weight: 400;
  text-align: center;
  line-height: 1.1;
}

/* Custome Radio */

.custom-radio .custom-control-label:before {
  content: "";
  border: 1px solid #d5d8de;
  width: 1.77592rem;
  height: 1.77592rem;
  margin: 0 0;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: "fiber_manual_record ";
  width: 1.77592rem;
  height: 1.77592rem;
  margin: 0 0;
  font-size: 1rem;
  font-family: "Material Icons";
  text-align: center;
  line-height: 1.8;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffffff;
  border-color: #4285f4;
}

/* switch */

.switch-button {
  display: inline-block;
  border-radius: 50px;
  background-color: #b3b3b3;
  width: 60px;
  height: 27px;
  padding: 4px;
  position: relative;
  vertical-align: middle;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.switch-button input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.switch-button label {
  border-radius: 50%;
  -webkit-box-shadow: 0 0 1px 1px #fff inset;
  box-shadow: 0 0 1px 1px #fff inset;
  background-color: #fff;
  margin: 0;
  height: 19px;
  width: 19px;
  z-index: 1;
  display: inline-block;
  cursor: pointer;
  background-clip: padding-box;
}

.switch-button label:before {
  position: absolute;
  font-size: 0.8462rem;
  font-weight: 600;
  z-index: 0;
  content: "OFF";
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 27px;
  top: 0;
  text-align: right;
  padding-right: 10px;
  color: #fff;
  border-radius: 50px;
}

.switch-button input[type="checkbox"]:checked + span {
  border-radius: 50px;
  background-color: #4285f4;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4px;
}

.switch-button input[type="checkbox"]:checked + span label {
  float: right;
  border-color: #2a75f3;
}

.switch-button input[type="checkbox"]:checked + span label:before {
  position: absolute;
  font-size: 0.8462rem;
  font-weight: 600;
  z-index: 0;
  content: "ON";
  color: #fff;
  left: 0;
  text-align: left;
  padding-left: 10px;
}

.switch-button input[type="checkbox"]:focus + span label:before {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.3);
  box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.3);
}

.switch-button input[type="checkbox"]:checked:focus + span label:before {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(66, 133, 244, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(66, 133, 244, 0.5);
}

.switch-button.switch-button-yesno label:before {
  content: "NO";
}

.switch-button.switch-button-yesno
  input[type="checkbox"]:checked
  + span
  label:before {
  content: "YES";
}

.switch-button.switch-button-xs {
  height: 20px;
  width: 53px;
  line-height: 16px;
  width: 50px;
}

.switch-button.switch-button-xs label {
  height: 12px;
  width: 12px;
}

.switch-button.switch-button-xs label:before {
  line-height: 21px;
}

.switch-button.switch-button-sm {
  height: 24px;
  width: 57px;
  line-height: 20px;
}

.switch-button.switch-button-sm label {
  height: 16px;
  width: 16px;
}

.switch-button.switch-button-sm label:before {
  line-height: 25px;
}

.switch-button.switch-button-lg {
  height: 30px;
  width: 63px;
  line-height: 26px;
  width: 65px;
}

.switch-button.switch-button-lg label {
  height: 22px;
  width: 22px;
}

.switch-button.switch-button-lg label:before {
  line-height: 31px;
}

.custom_switch .switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px;
}

.custom_switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 0px;
  background-color: #56b643;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_switch input:checked + .slider {
  background-color: #ececec;
}

.custom_switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.custom_switch input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */

.custom_switch .slider.round {
  border-radius: 34px;
}

.custom_switch .slider.round:before {
  border-radius: 50%;
}

/*  datetimepicker */

.datetimepicker.dropdown-menu {
  border-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.multiselect.dropdown-menu {
  overflow-y: scroll;
  height: 350px;
  transform: none !important;
  min-width: 7rem !important;
  top: 38px !important;
  right: 0px !important;
  padding: 0 !important;
}

.datetimepicker table {
  border-collapse: separate;
  border-spacing: 7px 2px;
}

.datetimepicker td,
.datetimepicker th {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.radius_15 {
  border-radius: 15px;
}

.radius_15 {
  border-radius: 15px;
}

.radius_30 {
  border-radius: 30px !important;
}

.radius_50 {
  border-radius: 50%;
}

.search_icon {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.custom_tab .nav-tabs li a {
  background-color: #ececec;
  color: #828282;
  display: block;
  padding: 30px 24px;
  font-family: "Poppins-semibold";
  font-size: 18px;
}

.custom_tab .nav-tabs li a.active {
  background-color: #fff;
  color: #000;
}

.requested_heading {
  background-color: #e5f6fd;
}

.delete_request {
  background-color: #fcefef;
  color: #e66161;
  padding: 5px 20px;
}

.approve_request {
  background-color: #eafde5;
  color: #2fe704;
  padding: 5px 20px;
}

.border-dashed {
  border-top: 1px dashed #76a400 !important;
}

/* #main {
    margin: 50px 0;
} */

#main #faq .card {
  margin-bottom: 0px;
  padding-bottom: 30px;
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid #cccaca;
}

#main #faq .card .card-body {
  padding-bottom: 0;
}

#main #faq .card .card-header {
  border: 0;
  /* -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5); */
  border-radius: 2px;
  background-color: #fff;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  /* color: #fff; */
  display: block;
  text-align: left;
  /* background: #ffe472; */
  color: #222;
  padding: 10px 70px 0 0 !important;
  position: relative;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  float: right;
  position: absolute;
  right: 9px;
  top: 5px;
  font-size: 30px;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapsing {
  /* background: #ffe472; */
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  /* background: #ffe472; */
  line-height: 30px;
  color: #222;
}

.dropdown-content {
  background-color: #fff;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  top: "100%";
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #ddd;
}

.green_btn {
  background-color: #d8f3db;
  color: #65ce70;
}

.red_btn {
  background-color: #ea433563;
  color: #ea4335;
}

.gray_btn {
  background-color: #e4e4e4;
  color: #767676;
}

.dot {
  height: 10px;
  min-width: 10px;
  background-color: #76a400;
  border-radius: 50%;
  display: inline-block;
}

.empty-dot {
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.create_btn {
  color: #76a400;
  border: 1px solid #76a400;
  border-radius: 7px;
}

.sky_btn {
  background-color: #76a40029;
  color: #76a400;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.border-blue {
  border: 1px solid #76a400 !important;
}

.border_bottom {
  border: 1px solid #dee2e6;
}

.custom_switch .switch.inactive .slider:before {
  background-color: red;
}

.accociate_pharmacy {
  background-color: #edf9fd;
  padding: 20px;
  border: 1px solid #76a400;
  border-radius: 10px;
}

.accociate_pharmacy h4 {
  color: #76a400;
  font-size: 18px;
}

.change_img {
  background-color: #059ad3a1;
  padding: 7px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

table thead th {
  white-space: nowrap;
  /* min-width: 200px; */
}

.div1 {
  width: 5%;
}

.div2 {
  width: 10%;
}

.div3 {
  width: 15%;
}

.div4 {
  width: 20%;
}

.div5 {
  width: 28%;
}

.div6 {
  width: 12%;
}

select {
  padding: 30px 30px 0 0;
  min-width: 140px;
  background-color: white;
  height: 32px;
  border: 0;
  border-radius: 0;
  margin: 0 auto;
  cursor: pointer;
  box-sizing: border-box;
  white-space: normal;
  word-wrap: break-word;
}

.select-barcode {
  border-radius: 15px;
  border: 1px solid #76a400;
}

.pending {
  color: #76a400 !important;
  text-transform: capitalize;
}

.received {
  color: #65ce70 !important;
  text-transform: capitalize;
}

.rejected {
  color: #ea4335 !important;
  text-transform: capitalize;
}

.w-233 {
  width: 233px !important;
}

#userDropdown {
  padding: 10px !important;
}

.multi-checkbox {
  display: flex;
  padding-left: 10px;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 4px;
  margin-top: 4px;
}
.multi-checkbox span {
  margin-left: 10px;
}
.bg-clear {
  background-color: transparent !important;
}
.car-logo-placeholder {
  width: 45px; /* Adjust size as needed */
  height: 45px; /* Adjust size as needed */
  background-color: #3498db; /* Adjust background color as needed */
  border: 1px solid #3498db; /* Adjust background color as needed */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 50%; /* Makes it a circle */
  text-transform: uppercase;
  padding: 3px;
}

/* ------------------
    Media Query 
----------------------*/

@media only screen and (min-width: 992px) {
  .toggled#page-content-wrapper {
    width: calc(100% - 270px);
    /* width: 100%; */
  }
  .toggled#top-header {
    width: 100%;
  }
}

@media only screen and (max-width: 991.98px) {
  #sidebar-wrapper {
    left: -288px;
  }
  .my-navbar {
    margin-left: 0px;
  }
  #page-content-wrapper {
    width: 100%;
    margin-left: 0px;
  }
  .toggled#sidebar-wrapper {
    left: 0;
    background-color: #1d522b;
  }
  .my-navbar.toggled {
    margin-left: var(--sidebar-width);
  }
  .toggled#page-content-wrapper {
    width: 100%;
    margin-left: var(--sidebar-width);
  }
  footer.footer {
    margin-left: 0;
  }
  .custom_tab .nav-tabs li a {
    font-size: 14px;
    padding: 18px 24px;
  }
}

@media (max-width: 767.98px) {
  .admin-error.admin-error-404 {
    padding: 50px 0 0;
  }
  .admin-error .error-number {
    padding: 20px 0;
    font-size: 6.923rem;
    margin-bottom: 10px;
    line-height: 100px;
  }
  .admin-error .error-description {
    font-size: 1.462rem;
  }
  .admin-error .error-goback-text {
    font-size: 1.15rem;
  }
  .w-70,
  .w-30 {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .admin-error-404 .error-container .error-number {
    padding: 0;
    font-size: 5.385rem;
  }
  .breadcrumb {
    padding: 2px;
  }
}

.gritter-item-wrapper .gritter-item .gritter-img-container {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image,
.gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image {
  padding: 10px 25px 10px 13px;
}

#gritter-notice-wrapper {
  right: 0;
  width: 100%;
  padding: 0 10px;
}

.gritter-image {
  max-width: 32px;
  max-height: 32px;
}

#sidebar-wrapper {
  /* left: -288px; */
}

.my-navbar {
  /* margin-left: 0px; */
}

/* #page-content-wrapper {
    width: calc(100% - 288px);
    margin-left: 288px;
} */

.toggled#sidebar-wrapper {
  /* left: -288px; */
  background-color: #1d522b;
}

.my-navbar.toggled {
  /* margin-left: var(--sidebar-width); */
}

.toggled#page-content-wrapper {
  width: 100%;
  /* margin-left: var(--sidebar-width); */
}

footer.footer {
  margin-left: 0;
}

.custom_tab .nav-tabs li a {
  font-size: 14px;
  padding: 18px 24px;
}

@media (max-width: 767.98px) {
  .admin-error.admin-error-404 {
    padding: 50px 0 0;
  }
  .admin-error .error-number {
    padding: 20px 0;
    font-size: 6.923rem;
    margin-bottom: 10px;
    line-height: 100px;
  }
  .admin-error .error-description {
    font-size: 1.462rem;
  }
  .admin-error .error-goback-text {
    font-size: 1.15rem;
  }
  .w-70,
  .w-30 {
    width: 100%;
  }
  .div1 {
    width: 100%;
  }
  .div2 {
    width: 100%;
  }
  .div3 {
    width: 100%;
  }
  .div4 {
    width: 200%;
  }
  .div5 {
    width: 200%;
  }
  .div6 {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .admin-error-404 .error-container .error-number {
    padding: 0;
    font-size: 5.385rem;
  }
}

.gritter-item-wrapper .gritter-item .gritter-img-container {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image,
.gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image {
  padding: 10px 25px 10px 13px;
}

#gritter-notice-wrapper {
  right: 0;
  width: 100%;
  padding: 0 10px;
}

.gritter-image {
  max-width: 32px;
  max-height: 32px;
}

@media (max-width: 340px) {
  .admin-error-404 .error-container .error-number {
    font-size: 3.846rem;
  }
}

@media (max-width: 295px) {
  .admin-error-404 .error-container .error-number {
    font-size: 3.077rem;
  }
}

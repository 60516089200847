a.read_mpopup,
a.read_mpopup:hover,
a.read_mpopup:focus,
a.read_mpopup:active {
    color: #76a400 !important;
    padding-left: 5px;
}

ul.status_btn_table {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.status_btn_table li {
    list-style-type: none;
    display: inline-block;
}

ul.status_btn_table li button {
    font-size: 15px;
    padding: 4px 15px;
    border-radius: 6px;
    font-weight: 500;
}

ul.status_btn_table li button:hover,
ul.status_btn_table li button:focus,
ul.status_btn_table li button:active {
    box-shadow: none;
}

ul.status_btn_table li button.reject_btn {
    background-color: #fdefef;
    color: #e66161;
    margin-right: 5px;
}

ul.status_btn_table li button.creat_account_btn {
    background-color: #76a400;
    color: #ffffff;
    margin-right: 0px;
}

.password_set {
    padding: 0 20px;
}

.password_input label {
    color: #c2c2c2;
    width: 100%;
}

.password_input input {
    background-color: #eeeeee;
    border: 1.5px solid #eeeeee;
    border-radius: 9px;
    width: 300px;
    height: 48px;
    color: #000000;
    font-size: 15px;
    padding-left: 15px;
}

.password_input input:focus {
    box-shadow: none;
    background-color: #eeeeee;
    border: 1.5px solid #76a400;
}

.password_input .button-psswd {
    background-color: #ffffff;
    border: none;
    color: #76a400;
    font-size: 15px;
    font-weight: 600;
    padding-left: 15px;
}

.password_input .button-psswd:hover,
.password_input .button-psswd:focus {
    box-shadow: none;
    border: none;
    color: #76a400;
    cursor: pointer;
}

ul.passw_btn {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.passw_btn li {
    list-style-type: none;
    display: inline-block;
}

ul.passw_btn li button {
    font-size: 15px;
    padding: 4px 15px;
    border-radius: 6px;
    font-weight: 600;
    width: 300px;
    height: 48px;
}

ul.passw_btn li button.cancel_passw {
    color: #000000;
    border: 2px solid #7e7e7e;
    background-color: #ffffff;
}

ul.passw_btn li button.submit_passw {
    color: #ffffff;
    border: 2px solid #76a400;
    background-color: #76a400;
}

.btn_primary {
    /* background-color: #cdeefa;
    color: #36b2ea; */
    background-color: #dcefda;
    color: #52b046;
    padding: 5px 15px;
    border-radius: 10px;
}

.btn_success {
    background-color: #dcefda;
    color: #52b046;
    padding: 5px 15px;
    border-radius: 10px;
}

.btn_delete {
    background-color: #fcefef;
    color: #e66161;
    padding: 5px 15px;
    border-radius: 10px;
}

.sidebar-elements .nav-link.active {
    position: relative;
}

.sidebar-elements .nav-link.active::after {
    content: "";
    background-repeat: no-repeat;
    position: absolute;
    top: 23px;
    right: 0;
    width: 20px;
    height: 14px;
}

.navbar-nav .has-sub>a::after {
    background-image: none !important;
    content: "\f106" !important;
    right: 20px !important;
    top: 50% !important;
    height: 30px !important;
    width: 30px !important;
}

.submenu-box .active::after {
    content: "";
    background-repeat: no-repeat;
    position: absolute;
    top: 24px;
    right: -8px;
    width: 20px;
    height: 14px;
}

.text-black {
    color: #000;
}

.cursor-pointer {
    cursor: pointer;
}

#main {
    margin: 50px 0;
}

#main #faq .card {
    margin-bottom: 30px;
    border: 0;
}

#main #faq .card .card-header {
    border: 0;
    border-radius: 2px;
    background-color: #fff;
    padding: 0;
}

#main #faq .card .card-header .btn-header-link {
    display: block;
    text-align: left;
    color: #222;
    padding: 20px 70px 0 0;
    position: relative;
}

#main #faq .card .card-header .btn-header-link:after {
    content: "\f107";
    font-family: "FontAwesome";
    font-weight: 900;
    float: right;
    font-size: 22px;
}


/* #main #faq .card .card-header .btn-header-link.collapsed {
    background: #a541bb;
    color: #fff;
} */

#main #faq .card .card-header .btn-header-link.collapsed:after {
    content: "\f106";
}

#main #faq .card {
    margin-bottom: 0;
    padding-bottom: 30px;
    border: 0;
    box-shadow: none;
    border-bottom: 1px solid #cccaca;
}

#main #faq .card .collapsing {
    line-height: 30px;
}

#main #faq .card .collapse {
    border: 0;
}

#main #faq .card .collapse.show {
    line-height: 30px;
    color: #222;
}

.custom_select {
    border-color: #76a400;
    color: #76a400;
    border-radius: 30px;
    height: auto;
}
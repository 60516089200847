.admin-login__wrapper {
  min-height: 100%;
  min-height: 100vh;
}

.admin-login__wrapper .admin-login__content {
  margin: 0;
}

.admin-login__content {
  position: relative;
  left: 0;
  margin-left: 230px;
  min-height: initial;
}

.admin-login__main-content {
  padding: 0px;
}

.admin-login__container {
  max-width: 433px;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
}

.admin-login__container .card {
  margin-bottom: 30px;
  background-color: #fff;
}

.card-border-color {
  border-top: 3px solid #c9c9c9 !important;
}

.card-border-color-primary {
  border-top-color: #4285f4 !important;
}

.admin-login__container .card .card-header {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 0;
}

.admin-login__description {
  text-align: center;
  display: block;
  line-height: 20px;
  font-size: 1rem;
  color: #5a5a5a;
  margin-top: 11px;
}

.admin-login__container .form-control {
  border-width: 1px;
  border-top-color: #bdc0c7;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 1.1538rem;
  -webkit-transition: none;
  transition: none;
  height: 3rem;
}

.row.login-tools {
  padding: 10px 0 0;
  margin-bottom: 16px;
}

.login-submit,
.row.login-submit {
  padding: 10px 0 0;
  margin-bottom: 1.3842rem;
}

.admin-login__title {
  text-align: center;
  display: block;
  font-size: 1.308rem;
  font-weight: 300;
}

.btn-social {
  min-width: 31px;
  padding: 0 8px;
  line-height: 38px;
}

.btn-color.btn-facebook {
  color: #fff;
  background-color: #5d82d1;
  border-color: #5d82d1;
}

.btn-color.btn-google-plus {
  color: #fff;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
}

.login_left_side {
  background-color: #fff;
  padding-bottom: 50px;
}

.focus_text {
  color: #76a400;
  font-size: 91px;
  line-height: 91px;
}

.dropbox_text {
  margin-top: 80px;
  color: #ffefa8;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.download_btn {
  border: 1px solid #76a400;
  border-radius: 8px;
  color: #76a400;
}

.sign_in_part {
  margin-top: 160px;
}

@media only screen and (max-width: 991.98px) {
  .w-70 {
    width: 100%;
  }
  .w-30 {
    width: 100%;
  }
  .focus_text {
    font-size: 40px;
    line-height: 40px;
    word-break: initial;
  }
  .dropbox_text {
    margin-top: 20px;
  }
  .sign_in_part {
    margin-top: 60px;
  }
}

@media (max-width: 575.98px) {
  .admin-login__container {
    min-width: 100%;
  }
  .focus_text {
    font-size: 30px;
    line-height: 35px;
  }
}

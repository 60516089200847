.arabic {
    direction: rtl;
}

.arabic .my-navbar {
    margin-right: 288px;
    margin-left: 0;
}

.arabic #page-content-wrapper {
    margin-left: 0;
    margin-right: 288px;
}

.arabic .card-body h2 {
    font-size: 16px;
}

.arabic .sidebar-elements li a>img {
    margin-left: 15px;
}

.arabic .navbar-nav .has-sub>a::after,
.arabic .navbar-nav .has-sub>a.open::after {
    left: 10px;
    right: initial !important;
}

.arabic .navbar-nav {
    margin-right: auto !important;
    margin-left: initial !important;
}

.arabic .navbar-expand .navbar-nav .dropdown-menu {
    left: 20px;
    right: initial;
}

.arabic .dropdown-menu-header .dropdown-item i {
    margin-left: 10px;
}

.arabic .toggled#page-content-wrapper,
.arabic .my-navbar.toggled {
    margin-left: 0;
    margin-right: 0;
}

.arabic .toggled#sidebar-wrapper {
    width: 0;
}

.arabic .dropdown-menu {
    right: initial !important;
    left: 10px !important;
}

.arabic .search_icon {
    left: 20px;
    right: initial;
}

.arabic .fa {
    margin-left: 8px;
}

.arabic .btn {
    margin: 0 12px;
}

.arabic li {
    margin: 0 12px;
}

.arabic .admin-login__container .form-check {
    padding-left: 0;
}

.arabic .admin-login__container .form-check label {
    margin-right: 20px;
}

.arabic .form-control {
    margin: 0 15px;
}